import React from 'react';
import { QuestionFormToggle } from './QuestionFormToggle';

export interface Props {
	checked: boolean;
	disabled: boolean;
	onChanged: (checked: boolean) => void;
}

export const TimedQestionToggle = (props: Props) => {
	return (
		<QuestionFormToggle
			checkedLabelText='Laufzeit festlegen'
			uncheckedLabelText='Laufzeit festlegen'
			checked={props.checked}
			disabled={props.disabled}
			onChanged={props.onChanged}
		/>
	);
};
