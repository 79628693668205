import { clone } from 'lodash';
import { CommandButton, FontIcon } from 'office-ui-fabric-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'src/store';
import { IAnswerOption } from '../../store/business/interfaces';
import styles from './ResultGraphRating.module.scss';

export interface IResultGraphRatingProps {
	answerOptions: IAnswerOption[];
	detailsIconName?: string;
	showDetails: boolean;
	onClickDetails: (answerOption: IAnswerOptionResult) => void;
	maxRating: number;
	fontSize: number;
}

export interface IAnswerOptionResult {
	id: string;
	title: string;
	averageRating?: number;
	starFilling?: number;
}

/**
 * calculates star-filling based on average result, which is rounded to 1 decimal.
 * @param props IResultGraphRatingProps
 * @returns question results in shape of a range of stars, filled according to result
 */
export const ResultGraphRating = (props: IResultGraphRatingProps) => {
	const survey = useSelector((state: AppState) => state.business.survey);
	const [result, setResult] = useState<IAnswerOptionResult[]>([]);

	useEffect(() => {
		if (!props.answerOptions) {
			return;
		}
		const clonedResult: IAnswerOptionResult[] = clone(props.answerOptions);
		clonedResult.forEach((cl) => {
			const averageRating = cl.averageRating ?? 0;
			const fontSize = Math.max(1, props.fontSize);
			cl.averageRating = Number(averageRating.toFixed(1));

			const gapFilling = Math.floor(averageRating) * (10 + fontSize);
			const averageRatingDecimal = averageRating - Math.floor(averageRating);
			const lastStarFilling = averageRatingDecimal > 0 ? ((fontSize / 4) + (averageRatingDecimal) * (fontSize / 2)) : 0;

			cl.starFilling = gapFilling + lastStarFilling;
		});
		if(survey.sortResultsByVotes) {
			clonedResult.sort((r1, r2) => { return r2.averageRating - r1.averageRating; });
		}
		setResult(clonedResult);
	}, [props.answerOptions, survey]);

	const renderDetailsButton = useCallback(
		(option: IAnswerOptionResult) => {
			if (!(props.onClickDetails && props.showDetails)) return <></>;
			return (
				<CommandButton
					title='Beteiligung anzeigen'
					className={styles.detailsButton}
					iconProps={{ iconName: props.detailsIconName ?? 'ReceiptCheck' }}
					onClick={() => props.onClickDetails(option)}
				/>
			);
		},
		[props.detailsIconName, props.onClickDetails]
	);

	return (
		<>
			{result.map((r, index) => {
				return (
					<div key={`rating-${index}`} className={styles.resultRatingGeneralContainer}>
						<span className={styles.averageResult}>{result[index].averageRating}</span>
						<span title={r.title} className={styles.answerTitle}>
							{r.title}
						</span>
						{renderDetailsButton(r)}
						<div className={styles.resultAnswerContainer} style={{ fontSize: props.fontSize }}>
							<div className={styles.ratingEmpty}>
								{[...Array(props.maxRating)].map((star, i) => {
									return <FontIcon key={`star-empty-${i}`} iconName='FavoriteStar' />;
								})}

								<div className={styles.ratingFill} style={{ width: `${result[index].starFilling}px` }}>
									{[...Array(props.maxRating)].map((star, i) => {
										return <FontIcon key={`star-filled-${i}`} iconName='FavoriteStarFill' />;
									})}
								</div>
								
							</div>
						</div>
					</div>
				);
			})}
		</>
	);
};
