import React, { useState } from 'react';
import styles from './QuestionOverview.module.scss';
import { FontIcon, CommandButton, IContextualMenuItem, ContextualMenuItemType } from 'office-ui-fabric-react';
import { IQuestion, ISurvey } from '../../store/business/interfaces';
import { SecurityIcon } from '../../shared/components/atoms/SecurityIcon';
import { QuestionConfigurationIcons } from '../../shared/components/atoms/QuestionConfigurationIcons';
import { QuestionKindIcon } from '../../shared/components/atoms/QuestionKindIcon';
import { QuestionStoppedIcon } from './QuestionStoppedIcon';
import { QuestionTitle } from '../atoms/QuestionTitle';
import { ConfirmationDialog } from '../atoms/ConfirmationDialog';
import { VotesSplittingIcon } from '../../shared/components/atoms/VotesSplittingIcon';
import { Badge } from 'src/shared/components/atoms/Badge';
import { formatDate } from 'src/shared/utils';

export interface Props {
	survey: ISurvey;
	questions: IQuestion[];
	onEdit: (question: IQuestion) => void;
	onDuplicate: (question: IQuestion) => void;
	onExport: (question: IQuestion) => void;
	onReset: (question: IQuestion) => void;
	onDelete: (question: IQuestion) => void;
	onSelect: (question: IQuestion) => void;
	onToggleQuestion: (question: IQuestion, start: boolean) => void;
}

export const QuestionOverview = (props: Props) => {
	const [hideDeleteQuestionDialog, setHideDeleteQuestionDialog] = useState('');
	const [hideRefreshQuestionDialog, setHideRefreshQuestionDialog] = useState('');
	const [hideStopQuestionDialog, setHideStopQuestionDialog] = useState('');
	return (
		<ul className={styles.questionOverviewList}>
			{!props.questions ? (
				<></>
			) : (
				props.questions
					.filter((q) => q.status !== 'unsaved')
					.map((q) => {
						let moreOptions: IContextualMenuItem[] = [
							{
								key: 'cloneQuestion',
								text: 'Duplizieren',
								iconProps: { iconName: 'DependencyAdd' },
								onClick: () => {
									props.onDuplicate(q);
								},
							},
							{
								key: 'exportQuestion',
								text: 'Abstimmungsprotokoll exportieren',
								iconProps: { iconName: 'Download' },
								onClick: () => {
									props.onExport(q);
								},
								disabled: q.status !== 'stopped',
							},
							{
								key: 'seperatorQuestion',
								itemType: ContextualMenuItemType.Divider,
							},
							{
								key: 'resetQuestion',
								text: 'Ergebnisse zurücksetzen',
								iconProps: { iconName: 'Refresh' },
								onClick: () => {
									setHideRefreshQuestionDialog('r' + q.id);
								},
								className: styles.rotateFlipIcon,
								disabled: q.status !== 'stopped' || props.survey.readOnly,
							},
							{
								key: 'deleteQuestion',
								text: 'Abstimmung löschen',
								iconProps: { iconName: 'Delete' },
								onClick: () => {
									setHideDeleteQuestionDialog('d' + q.id);
								},
								disabled: q.status !== 'new' || props.survey.readOnly,
							},
						];
						return (
							<li className={styles.questionOverviewListItem} key={q.id}>
								<ConfirmationDialog
									hidden={hideRefreshQuestionDialog !== 'r' + q.id}
									title={'Ergebnisse zurücksetzen'}
									message={
										q.title
											? `Wollen Sie die Ergebnisse von <b>${q.title}</b> wirklich zurücksetzen und alle Antworten löschen?`
											: 'Wollen Sie die Ergebnisse wirklich zurücksetzen und alle Antworten löschen?'
									}
									onConfirm={() => {
										setHideRefreshQuestionDialog('');
										props.onReset(q);
									}}
									onDismiss={() => {
										setHideRefreshQuestionDialog('');
									}}
								/>
								<ConfirmationDialog
									hidden={hideDeleteQuestionDialog !== 'd' + q.id || hideDeleteQuestionDialog === ''}
									title={'Abstimmung löschen'}
									message={
										q.title
											? `Wollen Sie die Abstimmung <b>${q.title}</b> inkl. Antworten wirklich löschen?`
											: `Wollen Sie diese Abstimmung inkl. Antworten wirklich löschen?`
									}
									onConfirm={() => {
										setHideDeleteQuestionDialog('');
										props.onDelete(q);
									}}
									onDismiss={() => {
										setHideDeleteQuestionDialog('');
									}}
								/>
								<ConfirmationDialog
									hidden={hideStopQuestionDialog !== 's' + q.id || hideStopQuestionDialog === ''}
									title={'Abstimmung stoppen'}
									message={
										q.title
											? `Wollen Sie die Abstimmung <b>${q.title}</b> wirklich stoppen?`
											: `Wollen Sie diese Abstimmung wirklich stoppen?`
									}
									onConfirm={() => {
										setHideStopQuestionDialog('');
										props.onToggleQuestion(q, false);
									}}
									onDismiss={() => {
										setHideStopQuestionDialog('');
									}}
								/>
								<div className={styles.questionOverviewListFlexItem}>
									<div className={styles.questionOverviewListStartButton}>
										{q.status === 'started' ? (
											<div className={styles.stopButtonContainer}>
												<CommandButton
													title='Abstimmung stoppen'
													onClick={() => {
														q.isTimerTriggered
															? setHideStopQuestionDialog('s' + q.id)
															: props.onToggleQuestion(q, false);
													}}
													disabled={props.survey.readOnly}
												/>
											</div>
										) : q.status === 'stopped' ? (
											<div className={styles.stoppedContainer}>
												<CommandButton title='Abstimmung gestoppt' iconProps={{ iconName: 'SkypeCircleCheck' }} />
											</div>
										) : (
											<div
												className={[styles.playButtonContainer, q.isTimerTriggered ? styles.disabled : ''].join(' ')}
											>
												<CommandButton
													title={
														!q.isTimerTriggered
															? 'Abstimmung starten'
															: 'Abstimmung startet und endet automatisch. Sie können diese Einstellung ändern, in dem Sie die Abstimmung bearbeiten.'
													}
													onClick={() => {
														props.onToggleQuestion(q, true);
														props.onSelect(q);
													}}
													disabled={props.survey.readOnly || q.isTimerTriggered}
												/>
											</div>
										)}
									</div>
									<div className={styles.questionOverviewListFlexItemColumn}>
										<div className={styles.questionOverviewListFlexItem}>
											<h4 className={styles.titleArea} onClick={() => props.onSelect(q)}>
												<QuestionTitle question={q} />
												<div className={styles.badgeArea}>
													{q.isTimerTriggered ? (
														<Badge
															icon='Calendar'
															information={
																formatDate(new Date(q.startTime)) !== formatDate(new Date(q.stopTime))
																	? `${formatDate(new Date(q.startTime))} - ${formatDate(new Date(q.stopTime))}`
																	: formatDate(new Date(q.startTime))
															}
														></Badge>
													) : (
														<></>
													)}
													{q.status === 'started' ? <Badge icon='Play' information='Läuft gerade'></Badge> : <></>}
												</div>
											</h4>
											<div className={[styles.questionOverviewListFlexItem, styles.buttonGroup].join(' ')}>
												<CommandButton
													iconProps={{ iconName: 'Edit' }}
													text='bearbeiten'
													disabled={q.status !== 'new' || props.survey.readOnly}
													onClick={() => {
														props.onEdit(q);
													}}
												/>
												{moreOptions.length > 0 && (
													<div className={styles.moreImageButton}>
														<CommandButton
															iconProps={{ iconName: 'More' }}
															menuIconProps={{ iconName: '' }}
															menuProps={{
																items: moreOptions,
															}}
														/>
													</div>
												)}
											</div>
										</div>
										<span className={styles.questionOverviewListItemMetadataContainer}>
											<span className={styles.questionOverviewListItemMetadata}>
												<QuestionKindIcon question={q} />
											</span>
											<span className={styles.questionOverviewListItemMetadata}>
												<SecurityIcon question={q} />
											</span>
											<span className={styles.questionOverviewListItemMetadata}>
												<QuestionConfigurationIcons question={q} numberOfOptions={q.answerOptions.length} />
											</span>
											{q.allowVotesSplitting && (
												<span className={styles.questionOverviewListItemMetadata}>
													<VotesSplittingIcon question={q} />
												</span>
											)}
											<span className={styles.questionOverviewListItemMetadata}>
												<FontIcon iconName='People' />
												<span>{q.votesCount}&nbsp;Teilnehmer</span>
											</span>
											{q.isTimerTriggered && new Date() < q.stopTime ? (
												<></>
											) : (
												<span className={styles.questionOverviewListItemMetadata}>
													<QuestionStoppedIcon question={q} />
												</span>
											)}
											<div className={styles.fillContainer} onClick={() => props.onSelect(q)}></div>
										</span>
									</div>
								</div>
							</li>
						);
					})
			)}
		</ul>
	);
};
