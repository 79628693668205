import React, { useEffect, useState } from 'react';
import { DayPickerStrings } from '../../i18n/datepicker.de';
import { DatePicker, DayOfWeek, MaskedTextField, Text } from 'office-ui-fabric-react';
import styles from './DateTimeRangePicker.module.scss';
import genStyles from '../molecules/Sidepanel.module.scss';

import format from 'date-fns/format';
import { IQuestion } from 'src/doc';

export interface Props {
	startDate: Date;
	stopDate: Date;
	question: IQuestion;
	visible: boolean;
	onDatesChange: (areValid: boolean) => void;
}

export const DateTimeRangePicker = (props: Props) => {
	const existingStartTimeHours =
		props.startDate?.getHours().toString().length > 1
			? props.startDate?.getHours().toString()
			: `0${props.startDate?.getHours().toString()}`;
	const existingStartTimeMinutes =
		props.startDate?.getMinutes().toString().length > 1
			? props.startDate?.getMinutes().toString()
			: `0${props.startDate?.getMinutes().toString()}`;
	const existingStopTimeHours =
		props.stopDate?.getHours().toString().length > 1
			? props.stopDate?.getHours().toString()
			: `0${props.stopDate?.getHours().toString()}`;
	const existingStopTimeMinutes =
		props.stopDate?.getMinutes().toString().length > 1
			? props.stopDate?.getMinutes().toString()
			: `0${props.stopDate?.getMinutes().toString()}`;
	const [startDate, setStartDate] = useState<Date>(props.question.startTime);
	const [startTime, setStartTime] = useState<string>(
		props.question.startTime ? `${existingStartTimeHours}:${existingStartTimeMinutes}` : ''
	);
	const [stopDate, setStopDate] = useState<Date>(props.question.stopTime);
	const [stopTime, setStopTime] = useState<string>(
		props.question.stopTime ? `${existingStopTimeHours}:${existingStopTimeMinutes}` : ''
	);
	const [everythingValid, setEverythingValid] = useState(true);

	useEffect(() => {
		if (startDate && startTime && stopDate && stopTime) {
			validateAndMergeDateTimes();
		}
	}, [startTime, stopTime, startDate, stopDate]);

	const onFormatDate = (date: Date): string => {
		return format(date, 'dd.MM.yyyy');
	};
	const maskFormat: { [key: string]: RegExp } = {
		'*': /[0-2]/,
		'+': /[0-9]/,
		'#': /[0-5]/,
		'-': /[0-9]/,
	};

	const validateAndMergeDateTimes = () => {
		const start = startDate;
		//Only proceed if entered times are complete
		if (/[0-2][0-9]:[0-5][0-9]/.test(startTime) && /[0-2][0-9]:[0-5][0-9]/.test(stopTime)) {
			const startHour = +startTime.substring(0, 2);
			const startMinutes = +startTime.substring(3);
			start.setHours(startHour);
			start.setMinutes(startMinutes);

			const stop = stopDate;
			const stopHour = +stopTime.substring(0, 2);
			const stopMinutes = +stopTime.substring(3);
			stop.setHours(stopHour);
			stop.setMinutes(stopMinutes);

			if (areDatesValid(start, stop) && isHourValidValue(startHour) && isHourValidValue(stopHour)) {
				props.question.startTime = start;
				props.question.stopTime = stop;
				props.onDatesChange(true);
				setEverythingValid(true);
			} else {
				props.onDatesChange(false);
				setEverythingValid(false);
			}
		} else {
			props.onDatesChange(false);
			setEverythingValid(false);
		}
	};

	const areDatesValid = (startTime: Date, stopTime: Date): boolean => {
		if (startTime.getTime() > new Date().getTime() && stopTime.getTime() > startTime.getTime()) {
			return true;
		} else {
			return false;
		}
	};
	//current mask pattern allows numbers 0-9 for second hour position, which is fine for the first 19 hours but has to stop at 24...
	const isHourValidValue = (hour: number) => {
		const firstDigit = String(hour)[0];
		const secondDigit = String(hour)[1];
		if (+firstDigit === 2 && +secondDigit > 4) {
			return false;
		} else {
			return true;
		}
	};

	return (
		<>
			{props.visible && (
				<div className={styles.container}>
					<div>
						Beginn
						<div className={styles.formArea}>
							<DatePicker
								className={styles.formItemDate}
								strings={DayPickerStrings}
								firstDayOfWeek={DayOfWeek.Monday}
								value={startDate}
								onSelectDate={(val) => {
									setStartDate(val);
								}}
								showGoToToday={false}
								formatDate={onFormatDate}
							/>
							<MaskedTextField
								className={styles.formItemTime}
								mask='*+:#-'
								maskFormat={maskFormat}
								onChange={(evt, val) => {
									setStartTime(val);
								}}
								iconProps={{ iconName: 'Clock', style: { color: '#4575a4' } }}
								value={props.startDate !== null ? `${existingStartTimeHours}:${existingStartTimeMinutes}` : ''}
							></MaskedTextField>
						</div>
					</div>
					<div>
						Ende
						<div className={styles.formArea}>
							<DatePicker
								className={styles.formItemDate}
								strings={DayPickerStrings}
								firstDayOfWeek={DayOfWeek.Monday}
								value={stopDate}
								onSelectDate={(val) => {
									setStopDate(val);
								}}
								showGoToToday={false}
								formatDate={onFormatDate}
							/>
							<MaskedTextField
								className={styles.formItemTime}
								mask='*+:#-'
								maskFormat={maskFormat}
								onChange={(evt, val) => {
									setStopTime(val);
								}}
								iconProps={{ iconName: 'Clock', style: { color: '#4575a4' } }}
								value={props.stopDate !== null ? `${existingStopTimeHours}:${existingStopTimeMinutes}` : ''}
							></MaskedTextField>
						</div>
					</div>

					{!everythingValid && (
						<Text variant='medium' block className={genStyles.errorMessage}>
							Bitte prüfen Sie, ob alle Datums- und Zeitangaben valide sind.
						</Text>
					)}
				</div>
			)}
		</>
	);
};
