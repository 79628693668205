import { IDatePickerStrings } from 'office-ui-fabric-react';

export const DayPickerStrings: IDatePickerStrings = {
	months: [
		'Januar',
		'Februar',
		'März',
		'April',
		'Mai',
		'Juni',
		'Juli',
		'August',
		'September',
		'Oktober',
		'November',
		'Dezember',
	],

	shortMonths: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],

	days: ['Sonntag', 'Montag', 'Diensttag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],

	shortDays: ['S', 'M', 'D', 'M', 'D', 'F', 'S'],

	goToToday: 'Heute',
	prevMonthAriaLabel: 'vorheriger Monat',
	nextMonthAriaLabel: 'nächster Monat',
	prevYearAriaLabel: 'vorheriges Jahr',
	nextYearAriaLabel: 'nächstes Jahr',
	invalidInputErrorMessage:
		'Bitte überprüfen Sie das alle Daten in der Zukunft und das Ende nach dem Beginn gewählt sind',
};
